import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { urlApi } from '../constant';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			civilite: '',
	  	lastname: '',
	  	email: '',
  		phone: '',
			object: '',
			file_1: '',
			file_2: '',
			description: '',
			srcurity: '',
			isVerify: '',
			titleNotification: '',
			textNotification: '',
			notification: false
		}
	}
	handleChange = (event) => this.setState({ [event.target.name]: event.target.value	});
	getFilePath = (e) => {
		let file = document.getElementById('file').files.length;
		if (file > 2) {
			alert('Vous avez sélectionné plus de 3 photos. \n Maximum autorisé : 2');
			document.getElementById('file').value = "";
		} else if (file === 1) {
			this.file_1(e);
		} else {
			this.file_1(e);
			this.file_2(e);
		}
	}
	file_1 = (e) => {
    let file_1 = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file_1)
    reader.onload = () => {
      this.setState({
        file_1: reader.result
      })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    }
	}
	file_2 = (e) => {
    let file_2 = e.target.files[1]
    let reader = new FileReader()
    reader.readAsDataURL(file_2)
    reader.onload = () => {
      this.setState({
        file_2: reader.result
      })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    }
	}
	onKeyPress(event) {
		if (event.which === 13) {
			event.preventDefault();
		}
	}
	countDown = () => {
		const start = 20;
		for (let i = start; i >= 0; i--) {
			setTimeout(() => {
				document.getElementById("secondes").innerHTML = start - i;
				if (i === 20) {
					window.location.reload();
				}
			}, i * 1000);
		}
  }
	componentDidMount() {
		this.getRandomInt();
	}
	getRandomInt = () => {
		let a = Math.floor(Math.random() * 10);
		let b = Math.floor(Math.random() * 10);
		document.getElementById("numberOne").innerHTML = a;
		document.getElementById("numberTwo").innerHTML = b;
		this.SommeRandomNumber(a, b);
	}
	SommeRandomNumber = (a, b) => {
		this.setState({ security: a + b });
	}
	handleSubmit = (event) => {
		event.preventDefault();
		const { civilite, lastname, email, phone, object, file_1, file_2, description } = this.state;
		if (this.state.isVerify === this.state.security.toString()) {
			document.getElementById("submit").innerHTML = "Patientez";
			document.getElementById("submit").disabled = true;
			fetch(`${urlApi}/send`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					data: {
						civilite, 
						lastname, 
						email, 
						phone, 
						object, 
						file_1,
						file_2,
						description
					}
			},
			body: JSON.stringify(this.state)
			})
			.then((res) => {
				if (res.ok) {
					document.getElementById("submit").innerHTML = "Envoyé !";
					document.getElementById("form").style.filter = "blur(4px)";
					this.setState({
						titleNotification: 'Merci de nous avoir contacté !',
						textNotification: 'Votre demande a bien été prise en compte, et sera traitée dans les meilleurs délais.'
					});
					document.getElementById("notification").style.visibility = "visible";
					this.countDown();
				} 
				this.setState({ notification: !this.state.notification });
			})
			.catch(err => {
				if (err) {
					document.getElementById("submit").innerHTML = "Erreur !";
					document.getElementById("form").style.filter = "blur(4px)";
					this.setState({
						titleNotification: 'Une erreur est survenue !',
						textNotification: "Nous sommes désolés, votre demande n'a pas pu aboutir en raison d'une erreur interne. Si vous souhaitez soumettre des images, merci d'en vérifier le poids total. Si l'erreur persiste, contactez-nous directement à l'adresse suivante : contact@as-sellerie.fr"
					});
					document.getElementById("notification").style.visibility = "visible";
					this.countDown();
				}
				this.setState({ notification: !this.state.notification });
			});
		}
		else {
			alert("Le résultat au calcul est manquant ou incorrect.");
		}
	}
	render() {
		const { civilite, lastname, email, phone, object, file, description, titleNotification, textNotification, isVerify } = this.state;
		return (
			<div id="contact" className="Contact">		
				<ContactForm 
					civilite={civilite}
					lastname={lastname}
					email={email}
					phone={phone}
					object={object}
					file={file}
					description={description}
					handleChange={this.handleChange}
					getFilePath={this.getFilePath}
					titleNotification={titleNotification}
					textNotification={textNotification}
					isVerify={isVerify}
					handleSubmit={this.handleSubmit}
					onKeyPress={this.onKeyPress}
					getRandomInt={this.getRandomInt}
				/>          
			</div>
		);
	}
}

export default Contact;