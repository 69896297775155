import React, { Component } from 'react';
import SellerieList from './SellerieList';
import { goData } from './data/goData';
import { dataList } from './data/dataList';
import './style/Sellerie.scss';

class Sellerie extends Component {
  constructor(props) {
    super(props);
    this.state = { isVideo: false }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if(!window.location.hash) {
      window.location = window.location + '#loaded';
      window.location.reload();
    }
  }
  isVideo = () => this.setState({ isVideo: !this.state.isVideo });

  render() {
    let path = this.props.match.params.value;
    const id = goData.find(section => section.href === path);
    const section = dataList[id.id].map(a => a);
    let type;
    if (this.state.isVideo) {
      type = "vidéos"
    } else {
      type = "images"
    }
    return (
      <div id="sellerie" className="Sellerie">
        <div 
          className="banniere" 
          style={{ backgroundImage: `radial-gradient(circle at center center, transparent 0%, rgba(0, 0, 0, 0.2) 100%), url(/images/sections/${id.img}.webp)` }}
        >
          <span className="title">{id.title.toUpperCase()}</span>
          <span className="filtre linkHover" onClick={this.isVideo}>{type}</span>
        </div>
        <div className="lateralBar" />
        <SellerieList
          path={path} 
          section={section} 
          isVideo={this.state.isVideo}
        />
      </div>
    );
  }
}

export default Sellerie;
