import React, { Component } from 'react';
import './Leather.scss';

class Leather extends Component {
	componentDidMount() {
		window.addEventListener("scroll", this.movePictureToUp);
	}
	movePictureToUp = () => {
		let base = -500;
		let result = base + window.scrollY / 10;
		if (document.getElementById("moreLeather") != null) {
			if (window.scrollY >= document.getElementById("moreLeather").offsetTop - 400) {
				base += 100;
				document.getElementById("moreLeather").style.top = result + "px";
			}
		}
	}
	render() {
		return (
			<div id="leather" className="Leather">
				<div className="leatherContain">
					<div className="squareContainer">
						<div id="moreLeather" className="moreLeather"></div>
						<div className="squareOne"></div>
						<div className="squareTwo"></div>
					</div>
					<p className="text-leather">
						Du vinyle, de l'alcantara, du tissu ou même du cuir (véritable, synthétique ou simili), nous vous proposons un large éventail de motifs et de couleurs. <br />
						Nos fournisseurs sont spécialisés dans la production de matières propres et hautement qualitatives. Ils s'étendent dans toute l'Europe. <br />
						De cette manière, nos prix s'adaptent à votre budget en fonction de vos besoins.
						<br />
						<br />
						Nos travaux respectent la bonne tenue de vos équipements, il s'agit d'un combiné de créations sur-mesure, de conforts et d'esthétisme. <br />
						Nous nous adaptons à chaque style et méthode de fabrication, chaque élément est assuré avec minutie et précision par les mains expertes de notre sellier garnisseur.
						<br />
						<br />
						Nous vous invitons à découvrir des collections rares et plus communes, pour soigner et habiller vos selleries dans une technique qui respecte un savoir ancestral.
					</p>
				</div>
			</div>
		);
	}
}

export default Leather;
