import React, { Component } from 'react';
import Homepage from './homepage/Homepage';
import AboutUs from './aboutus/AboutUs';
import Leather from './leather/Leather';
import Contact from './contact/Contact';

class AppFront extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="AppFront">
        <Homepage />
        <AboutUs />
        <Leather />
        <Contact />
      </div>
    );
  }
}

export default AppFront;
