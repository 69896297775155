import React, { Component } from 'react';
import './AboutUs.scss';

class AboutUs extends Component {
	componentDidMount() {
		window.addEventListener("scroll", this.movePictureToUp);
		let svgLine = document.getElementById("path");
		svgLine.style.strokeDasharray = 3200;
		svgLine.style.strokeDashoffset = 3200;
	}
	movePictureToUp = () => {
		let base = -600;
		let result = base + window.scrollY / 10;
		const aboutus = document.getElementById("aboutus");
		if (aboutus != null) {
			if (window.scrollY >= aboutus.offsetTop - 400 && window.innerWidth > 1200) {
				base += 100;
				document.getElementById("sellier").style.bottom = result + "px";
			}
			let svgLine = document.getElementById("path");
			let length = svgLine.getTotalLength();
			if (window.scrollY < 1000) {
				svgLine.style.strokeDashoffset = length + 100;
			}
			if (window.scrollY >= 1000) {
				svgLine.style.strokeDashoffset = length / 1.2;
			}
			if (window.scrollY >= 1200) {
				svgLine.style.strokeDashoffset = length / 2.5;
				svgLine.style.strokeWidth = "1";
			}
			if (window.scrollY >= 1500) {
				svgLine.style.strokeDashoffset = 0;
				svgLine.style.strokeWidth = "1.5";
			}
		}
	}
	render() {
		return (
      <div id="aboutus" className="AboutUs">
				<div className="title">
					<h2>Votre artisan d'art</h2>
				</div>
				<div className="aboutUsContain">
					<p className="text-aboutus">
						Des années mêmes avant la consécration à mon artisanat, je soignais ma passion et mon expérience pour la réalisation de beaux objets.
						<br />
						<br />
						Le respect, et le travail bien fait sont plus que jamais mes valeurs, afin de garantir un résultat au plus juste de la branche traditionnelle, 
						en perpétuant les gestes et le savoir de nos artisans d'hier.
						<br />
						<br />
						Un savoir diversifié par les domaines de l'auto, moto, camping-car, tapisserie, équipement médical et nautique. 
						Quel que soit votre besoin, de la création à la rénovation, ou la personnalisation, je prendrai soin de vous conseiller.
						<br />
						<br />
						Nos clients comptent parmi des professionnels, et des particuliers de toute la France, ainsi un large choix de couleurs et de matières vous sont proposés, 
						afin que le résultat corresponde à vos attentes.
						<br />
						<br />
						<br />
						<span className="author-text">Votre sellier garnisseur</span>
          </p>
					<div className="squareContainer">
						<div className="squareOne"></div>
						<div className="squareTwo"></div>
						<div id="sellier" className="sellier"></div>
					</div>
				</div>
				<div className="line-container">
					<svg className="line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1601.32 800">
						<path id="path" className="path" d="M0,386c55.6,69.66,111.5,110.8,151,135,107.92,66.13,231,90.11,333,110,13.71,2.67,39.79,8.17,92,19,28.84,6,50.72,7.92,82,18,22.84,7.36,34.28,11,46,18,31.74,19,63.93,59.72,66,112,3.08,77.72-62.77,121-66,123-57.06,36-135.51,26.17-181-21-44.65-46.3-38.16-108.44-37-118,1.76-14.57,10.28-66.78,54-98,61.21-43.71,147.3-14.8,192,18,32,23.5,52.48,56.09,70,84,17.37,27.68,32.85,59.93,42,79,10.51,21.9,18.22,39.91,34,69,0,0,9.87,17.75,20,35,32.83,55.86,63.08,112.61,113,139,72.64,38.4,172.43,29.38,188-5,4.59-10.12,4.15-27.56-4-41-10-16.53-27.79-20.54-30-21-2-.43-20.57-4-37,8-.77.56-12.94,9.59-17.24,24.1-.36,1.24-.6,2.23-.76,2.9-1,4.09-3,13.48,1,24,4.12,10.73,12.28,16.51,16,19,14.06,9.4,29.31,8.44,35,8,12.73-1,25.49-8,51-22,10.83-6,11.15-6.61,23-13,8.51-4.59,20.92-11.29,34-17a322.67,322.67,0,0,1,49-16c46.22-10.93,102-8.32,115-8,37.71.94,93-8,166-55" transform="translate(0.78 -385.38)"/>
					</svg>
				</div>
      </div>
    );
  }
}

export default AboutUs;
