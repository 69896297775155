import React, { Component } from 'react';
import './style/SellerieList.scss';

class SellerieList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      isOpen: false
    }
  }
  openModal = (e) => {
    let img = e.target.id;
    this.setState({
      id: img,
      isOpen: true 
    });
  };
  closeModal = () => this.setState({ isOpen: false });

  render() {
    let modal = "modal";
    let bgModal = "bgModal";
    if (this.state.isOpen) {
      modal += " isOpen";
      bgModal += " isOpen";
    }
    const { section } = this.props;
    return (
      <div className="SellerieList">
        {
          section.filter(type => type.isVideo === this.props.isVideo).map((section, i ) =>
            (!this.props.isVideo)
            ?
            <div
              id={i}
              className="img linkHover"
              onClick={this.openModal}
              style={{ backgroundImage: `radial-gradient(circle at center center, transparent 0%, rgba(0, 0, 0, 0.2) 100%), url(${section.imagePath})` }}
              key={i}
            ></div>
            :
            (section.imagePath !== "")
            ?
            <iframe
              className="video linkHover"
              width="100%"
              title={section.title}
              src={`https://www.youtube.com/embed/${section.imagePath}`}
              key={i}
              >
            </iframe>
            :
            <p>Cette section ne contient pas de vidéos pour le moment.</p>
          )
        }
        <div 
          className={modal}
          style={{ backgroundImage: `url(/sellerie/${this.props.path}/${this.state.id}.jpg)` }}
        >
          <div className="close linkHover" onClick={this.closeModal}>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={bgModal}></div>
      </div>
    );
  }
}

export default SellerieList;
