import React, { Component } from 'react';
import Mouse from './mouse/Mouse';
import Navbar from './navbar/Navbar';
import AppFront from './AppFront';
import Sellerie from './sellerie/Sellerie';
import LegalNotice from './footer/LegalNotice';
import Footer from './footer/Footer';
import { Switch, Route } from 'react-router-dom';
import './App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Mouse />
        <Navbar />
          <Switch>
            <Route exact path="/" component={AppFront} />
            <Route exact path="/sellerie/:value" component={Sellerie} />
            <Route exact path="/mentions-legales" component={LegalNotice} />
          </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
