import React, { Component } from 'react';
import './ContactForm.scss';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false }
  }
  handleOver = () => { this.setState({ isOpen: true }) }
  handleOut = () => { this.setState({ isOpen: false }) }
	render() {
    let infoBulle = "infoBulle";
    if (this.state.isOpen) {
      infoBulle += " Open";
    }
    const { civilite, lastname, email, phone, object, description, getFilePath, titleNotification, textNotification, isVerify, handleChange, handleSubmit, onKeyPress } = this.props;
    return (
      <div className="ContactForm">
				<div className="smoke"></div>
				<div className="formContainer">
          <form id="form" onSubmit={handleSubmit} onKeyPress={onKeyPress}>
            <div className="fieldGroup">
              <div className="field">
                <label htmlFor="civilite">Civilité <span className="required">(*)</span></label>
                <select id="civilite" name="civilite" value={civilite} onChange={handleChange} required>
                  <option defaultValue disabled hidden></option>
                  <option>Monsieur</option>
                  <option>Madame</option>
                  <option>Autre</option>
                </select>
              </div>
              <div className="field">
                <label htmlFor="lastname">Nom <span className="required">(*)</span></label>
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={lastname.toUpperCase()}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="fieldGroup">
              <div className="field">
                <label htmlFor="email">Email <span className="required">(*)</span></label>
                <input
                  name="email"
                  id='email'
                  type="text"
                  value={email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="field">
                <label htmlFor="phone">N° de téléphone <span className="required">(*)</span></label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="fieldGroup">
              <div className="field">
                <label htmlFor="object">Objet <span className="required">(*)</span></label>
                <select id="object" name="object" value={object} onChange={handleChange} required>
                  <option defaultValue disabled hidden></option>
                  <option>Auto</option>
                  <option>Moto</option>
                  <option>Tapisserie</option>
                  <option>Médical</option>
                  <option>Nautique</option>
                  <option>Autre</option>
              </select>
              </div>
              <div className="field pj">
                <span className="infoIcon linkHover" onMouseOver={this.handleOver} onMouseOut={this.handleOut}>i</span>
                <p className={infoBulle}>
                  Vous pouvez sélectionner 2 photos (maximum), en pressant la touche CTRL + clique sur les photos à envoyer. <br />
                  Attention, le poids total de vos images ne doit pas excéder 50mb.
                </p>
                <label htmlFor="file">PJ - (1 à 2 max) <span className="optional">(facultatif)</span></label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  multiple
                  accept=".jpg, .jpeg, .png, .pdf, .bmp, .doc, .docx"
                  onChange={getFilePath}
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="description">Votre message <span className="optional">(facultatif)</span></label>
              <textarea 
                id="description"
                name="description"
                type="text"
                rows={10}
                value={description}
                onChange={handleChange}
              />
            </div>
            <div className="buttonGroup">
              <div className="securityGroup">
                <label htmlFor="isVerify" className="security">
                  <span id="numberOne">5</span> + <span id="numberTwo">7</span> = 
                </label>
                <input
                  id="isVerify"
                  name="isVerify"
                  type="number"
                  value={isVerify}
                  onChange={handleChange}
                />
              </div>
              <button className="linkHover" id="submit" type="submit">Soumettre</button>
            </div>
          </form>
        </div>
        <div id="notification" className="notification">
          {
            (titleNotification.length < 30)
            ?
            <h4 style={{ color: "#EA5A46" }}>{titleNotification}</h4>
            :
            <h4>{titleNotification}</h4>
          }
          <p>{textNotification}</p>
          <p>La page va être rafraîchie dans <span id="secondes"></span> seconde(s).</p>
        </div>
      </div>
    );
  }
}

export default ContactForm;
